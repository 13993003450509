const openFile = async (filename, currentDirectory, setOutput, directories, contentRef, output) => {
  // Normalize paths
  const normalizePath = (path) => path.replace(/\/+/g, '/').replace(/\/$/, '');

  // Resolve full path
  const resolveFullPath = (filename, currentDirectory) => {
    if (filename.startsWith('/')) {
      return normalizePath(filename);
    }
    const parts = filename.split('/').filter(Boolean);
    const currentParts = currentDirectory.split('/').filter(Boolean);

    parts.forEach(part => {
      if (part === '..') {
        currentParts.pop();
      } else if (part !== '.') {
        currentParts.push(part);
      }
    });

    return '/' + currentParts.join('/');
  };

  const fullPath = resolveFullPath(filename, currentDirectory);
  console.log("Resolved full path:", fullPath);
  const dirPath = fullPath.substring(0, fullPath.lastIndexOf('/'));
  const fileInDir = fullPath.substring(fullPath.lastIndexOf('/') + 1);

  const normalizedDirPath = normalizePath(dirPath).toLowerCase(); // Make path comparison case-insensitive
  console.log("Normalized directory path:", normalizedDirPath);

  // Find the correct directory case
  const realDirPath = Object.keys(directories).find(dir => dir.toLowerCase() === normalizedDirPath) || normalizedDirPath;
  const filesInDir = directories[realDirPath] || [];
  console.log("Files in directory:", filesInDir);

  // Find the file in a case-insensitive manner and without needing the extension
  const findFile = (fileInDir, filesInDir) => {
    const lowerFileInDir = fileInDir.toLowerCase();
    return filesInDir.find(file => file.toLowerCase().startsWith(lowerFileInDir));
  };

  const fullFilename = findFile(fileInDir, filesInDir) || fileInDir;
  console.log("Full filename found:", fullFilename);
  const baseStorageUrl = process.env.REACT_APP_FILE_STORAGE.replace(/\/+$/, '');
  const filePath = `${realDirPath}/${fullFilename}`.replace(/^\/+/, ''); // Use the correct case path
  const fileUrl = `${baseStorageUrl}/${filePath}`;

  console.log("Attempting to open file at URL:", fileUrl);

  const scrollToBottom = () => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  };

  // Determine the file type and render the appropriate output
  const fileExt = fullFilename.split('.').pop().toLowerCase();

  if (['png', 'jpg', 'jpeg'].includes(fileExt)) {
    const imageOutput = (
      <div key={output.length}>
        <span>{'> open '}{fullFilename}{'\n'}</span>
        <img src={fileUrl} alt={filename} style={{ maxWidth: '100%', maxHeight: '500px', objectFit: 'contain' }} onLoad={scrollToBottom} />
      </div>
    );
    console.log("Rendering image output");
    setOutput(prevOutput => [...prevOutput, imageOutput]);
  } else if (['mp4', 'mov'].includes(fileExt)) {
    const videoOutput = (
      <div key={output.length}>
        <span>{'> open '}{fullFilename}{'\n'}</span>
        <video
          controls
          style={{ maxWidth: '100%', maxHeight: '500px', objectFit: 'contain' }}
          onLoadedData={scrollToBottom}
          onError={(e) => console.error('Error loading video:', e)}
        >
          <source src={fileUrl} type={`video/${fileExt}`} />
          Your browser does not support the video tag.
        </video>
      </div>
    );
    console.log("Rendering video output");
    setOutput(prevOutput => [...prevOutput, videoOutput]);
  } else {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('File not found');
      }
      const data = await response.text();
      console.log("Rendering text file output");

      // Function to convert URLs in text to clickable links
      const convertToLinks = (text) => {
        const urlRegex = /https?:\/\/[^\s]+/g;
        return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
      };

      // Convert text with URLs to HTML
      const htmlData = convertToLinks(data);

      const htmlOutput = (
        <div key={output.length}>
          <span>{'> open '}{fullFilename}{'\n'}</span>
          <div dangerouslySetInnerHTML={{ __html: htmlData }} />
        </div>
      );

      setOutput(prevOutput => [...prevOutput, htmlOutput]);
    } catch (error) {
      console.error('Error opening file:', error);
      setOutput(prevOutput => [...prevOutput, `> open ${fullFilename}`, 'Error: File not found']);
    }
  }
};

export default openFile;
