import React, { useEffect, useState } from 'react';
import styles from './RightOverlay.module.css';

const RightSlidingOverlay = ({ show }) => {
  const [animationState, setAnimationState] = useState('startRight');

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setAnimationState('runningRight'), 1237);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <div className={`${styles.slidingOverlayRight} ${styles[animationState]}`}></div>
  );
};

export default RightSlidingOverlay;
