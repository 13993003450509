import React, { useState, useEffect, useRef } from 'react';
import styles from './LatestUpdates.module.css';
import axios from 'axios';

const LatestUpdates = ({ openFileFromButton }) => {
  const [updates, setUpdates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial number of items per page
  const updatesRef = useRef(null);

  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const response = await axios.get('https://www.matt-os.com/api/list-files');
        const rootUpdates = response.data
          .filter(file => file.name.includes('root'))  // Filter for files with 'root' in the path
          .map(file => {
            const parts = file.name.split('/');
            const fileName = parts.pop();
            const isFolder = fileName === ''; // Check if it's a folder

            return {
              title: isFolder ? `${parts.join('/')} folder added` : `${fileName} added`,
              date: new Date(file.updated),
              name: fileName || parts.join('/'), // Use folder path if it's a folder
              path: file.name  // Include the full path
            };
          });

        rootUpdates.sort((a, b) => new Date(b.date) - new Date(a.date));

        setUpdates(rootUpdates);
      } catch (error) {
        console.error("Failed to fetch updates:", error);
      }
    };

    fetchUpdates();
  }, []);

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (updatesRef.current) {
        const updateItemHeight = 60; // Approximate height of each update item in pixels
        const containerHeight = window.innerHeight - 121; // 61px for top offset + 60px for pagination controls
        const newItemsPerPage = Math.floor(containerHeight / updateItemHeight); // Adjust to fit the container
        setItemsPerPage(newItemsPerPage > 0 ? newItemsPerPage : 1); // Ensure at least one item per page
      }
    };

    window.addEventListener('resize', updateItemsPerPage);
    updateItemsPerPage(); // Initial calculation

    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  // Calculate the number of pages
  const numPages = Math.ceil(updates.length / itemsPerPage);

  // Get updates for the current page
  const currentUpdates = updates.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <aside className={styles.latestUpdates} ref={updatesRef}>
      <h2>Latest Updates</h2>
      <ul>
        {currentUpdates.map((update, index) => (
          <li key={index} className={styles.updateItem} onClick={() => openFileFromButton(update.path)}>
            <div className={styles.updateContent}>
              <span className={styles.updateTitle}>{update.title}</span>
              <span className={styles.updateDate}>{update.date.toLocaleDateString()}</span>
            </div>
          </li>
        ))}
      </ul>
      <div className={styles.paginationControls}>
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
          disabled={currentPage === 0}
        >
          &lt; 
        </button>
        <span>{currentPage + 1} / {numPages}</span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, numPages - 1))}
          disabled={currentPage === numPages - 1}
        >
          &gt;
        </button>
      </div>
    </aside>
  );
};

export default LatestUpdates;
