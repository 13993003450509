import React, { useState, useEffect } from 'react';
import styles from './MainContent.module.css';
import { HelpOutput } from './commands/help';
import clearTerminal from './commands/clear';
import ListDirectoryContents from './commands/ls';
import changeDirectory from './commands/cd';
import openFile from './commands/open';
import handleRunCommand from './commands/run'; // Correctly importing the run command handler
import axios from 'axios';

// Utility function to detect if the user is on a mobile device
function isMobileDevice() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

// Custom hook to manage directories
const useDirectories = (setDirectories) => {
  useEffect(() => {
    const fetchDirectories = async () => {
      try {
        const response = await axios.get('https://www.matt-os.com/api/list-files');
        const files = response.data.reduce((acc, file) => {
          const parts = file.name.split('/');
          const filename = parts.pop();
          let dirPath = '';

          parts.forEach(part => {
            dirPath += '/' + part;
            acc[dirPath] = acc[dirPath] || [];
          });

          // Only add if the filename is not empty
          if (filename) {
            acc[dirPath].push(filename);
          }

          return acc;
        }, {});
        setDirectories(files);
      } catch (error) {
        console.error('Failed to fetch directories:', error);
      }
    };

    fetchDirectories();
  }, [setDirectories]);
};

// Function to format output
const formatOutput = (text) => {
  const lines = text.split('\n');
  return lines.map((line, index) => (
    <div key={index} style={{ fontSize: '14px' }}>
      <span style={{ color: 'grey' }}>{'> '}</span>
      <span>{line}</span>
    </div>
  ));
};

// MainContent component
const MainContent = ({ output, setOutput, directories, setDirectories, contentRef, show, currentDirectory, setCurrentDirectory }) => {
  const [animationState, setAnimationState] = useState('start');
  const [userInput, setUserInput] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const isMobile = isMobileDevice();

  useDirectories(setDirectories);

  useEffect(() => {
    if (show) setAnimationState('running');
  }, [show]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [output]);

  const handleUserInput = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const trimmedInput = userInput.trim();
      const inputParts = trimmedInput.split(' ');

      const updateOutput = (newOutput) => {
        setOutput(prevOutput => [...prevOutput, newOutput]);
        setUserInput('');
      };

      if (trimmedInput) {
        setCommandHistory([...commandHistory, trimmedInput]);
        setHistoryIndex(-1);
      }

      switch (inputParts[0].toLowerCase()) {
        case 'clear':
          clearTerminal(setOutput);
          setUserInput('');
          break;
        case 'help':
          updateOutput(`> help`);
          updateOutput(<HelpOutput formatOutput={formatOutput} />);
          break;
        case 'ls':
          const contents = (
            <ListDirectoryContents currentDirectory={currentDirectory} directories={directories} />
          );
          updateOutput(`> ${trimmedInput}`);
          updateOutput(contents);
          break;
        case 'cd':
          if (inputParts.length > 1) {
            const directoryName = inputParts.slice(1).join(' ');
            const message = changeDirectory('cd ' + directoryName, currentDirectory, directories, setCurrentDirectory);
            updateOutput(message);
          }
          break;
        case 'open':
          if (inputParts.length > 1) {
            const filename = inputParts[1];
            const initialMessage = `> ${trimmedInput}`;
            updateOutput(initialMessage);
            await openFile(filename, currentDirectory, setOutput, directories, contentRef, output);
          }
          break;
        case 'run':
          if (inputParts.length > 1) {
            handleRunCommand(trimmedInput, setOutput);
          }
          break;
        default:
          updateOutput(`> ${trimmedInput}`);
          updateOutput('Error: Command not recognized');
      }
    } else if (event.key === 'ArrowUp') {
      if (commandHistory.length > 0 && historyIndex < commandHistory.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setUserInput(commandHistory[commandHistory.length - 1 - newIndex]);
      }
    } else if (event.key === 'ArrowDown') {
      if (historyIndex > 0) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setUserInput(commandHistory[commandHistory.length - 1 - newIndex]);
      } else {
        setHistoryIndex(-1);
        setUserInput('');
      }
    }
  };

  return (
    <div className={`${styles.screen} ${styles[animationState]}`}>
      <div className={styles.whiteNoise}></div>
      <div className={`${styles.contentText} ${styles.scrollable} ${isMobile ? styles.mobilePadding : ''}`} ref={contentRef}>
        {output.map((item, index) => (
          <React.Fragment key={index}>
            {typeof item === 'string' ? <div style={{ fontSize: '14px' }}>{item}</div> : item}
          </React.Fragment>
        ))}
      </div>
      <div className={`${styles.inputContainer} ${isMobile ? styles.mobileInputContainer : ''}`}>
        <textarea
          className={styles.terminalInput}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleUserInput}
          placeholder={`> ${currentDirectory}`}
          style={{ fontSize: '14px' }}
        />
      </div>
    </div>
  );
};

export default MainContent;
