import React, { useEffect, useState } from 'react';
import styles from './LeftOverlay.module.css';

const LeftSlidingOverlay = ({ show }) => {
  const [animationState, setAnimationState] = useState('doneLeft');

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setAnimationState('runningLeft'), 370);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <div className={`${styles.slidingOverlayLeft} ${styles[animationState]}`}></div>
  );
};

export default LeftSlidingOverlay;
