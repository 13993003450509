// commands/help.js
import React from "react";

const helpInfo = {
  ls: '   - list files in the current working directory',
  cd:`   - change directory; where "." represents the current working directory
   - e.g: "cd ./projects" or simply "cd projects" to access the projects 
          folder from /matt-os
   - "cd projects/glados" to open the glados project folder from /matt-os
   - use "cd .." to move to parent directory
    `,
  open:
    `   - open .txt, .img and .mp4 files (file extension not required)
   - e.g: "open welcome.txt" or simply "open welcome"
    `,
  run: `   - run .exe programs (file extension not required)
   - e.g "run matrixcam.exe" or simply "run matrixcam"
   - Ctrl + C to return to the terminal
      `,
  clear: '   - clear the terminal',
  // fs: '   - toggle full screen terminal (not yet implemented)',
  help: '   - list available commands',
};

export const HelpCommands = helpInfo; // Exporting the help information

// This component will be responsible for formatting the help output
export const HelpOutput = ({ formatOutput }) => {
  return Object.entries(helpInfo).map(([command, description]) => {
    const formattedDescription = formatOutput(description);

    return (
      <React.Fragment key={command}>
        <span style={{ color: 'grey' }}>{'> '}</span>
        <span style={{ color: '#f93816' }}>{command}</span>
        {formattedDescription.map((desc, index) => (
          <React.Fragment key={index}>
            <span style={{ color: 'orange' }}>{desc}</span>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  });
};
