import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';

// Welcome Animation
import WelcomeAnimation from './components/welcome-animation';

// Nav Bar and menu
import Navbar from './components/navigation-bar/Navbar';
import NavItem from './components/navigation-bar/NavItem';
import DropdownMenu from './components/navigation-bar/DropdownMenu';

// Nav Icons
import { ReactComponent as HomeIcon } from './assets/icons/home.svg';
import { ReactComponent as CodeIcon } from './assets/icons/code.svg';
import { ReactComponent as BlogIcon } from './assets/icons/blog.svg';
import { ReactComponent as ConnectIcon } from './assets/icons/connect.svg';
import MatrixCamIcon from './assets/icons/matrixcam.png'; // Import as default

// Home Components
import Home from './components/home/home';
import MainContent from './components/home/MainContent';
import openFileFromButton from './components/home/MainContent/commands/openFileFromButton';
import openFile from './components/home/MainContent/commands/open';
import MatrixRain from './components/home/MatrixRain'; 

// New Project Page
import MatrixCam from './components/matrix-cam/MatrixCam';

// Layout component to apply route-specific logic
const Layout = ({ children, showNavbar }) => {
  const location = useLocation();
  const isMatrixCam = location.pathname === '/matrix-cam';
  
  return (
    <>
      {!isMatrixCam && (
        <div className={`navbar ${showNavbar ? 'fade-in' : ''}`}>
          <Navbar>
            <NavItem icon={<img src={MatrixCamIcon} alt="MatrixCam" style={{ width: '38px', height: '38px' }} />} href="/matrix-cam" />
            {/* <NavItem icon={<BlogIcon />} /> */}
            <NavItem icon={<ConnectIcon />}>
              <DropdownMenu />
            </NavItem>
          </Navbar>
        </div>
      )}
      <div className={`appPage ${isMatrixCam ? 'matrixCamPage' : ''}`}>
        {children}
      </div>
    </>
  );
};

function App() {
  const [welcomeDone, setWelcomeDone] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [output, setOutput] = useState(['> Type "help" for a list of commands.']);
  const [directories, setDirectories] = useState({});
  const [currentDirectory, setCurrentDirectory] = useState('/root/matt-os');
  const contentRef = useRef(null);

  const handleAnimationDone = () => {
    setWelcomeDone(true);
    setShowNavbar(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResizeScroll = () => {
      if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
        document.activeElement.scrollIntoView();
      }
    };

    window.addEventListener('resize', handleResizeScroll);
    return () => window.removeEventListener('resize', handleResizeScroll);
  }, []);

  return (
    <Router>
      <MatrixRain show={true} />

      {!welcomeDone && <WelcomeAnimation onDone={handleAnimationDone} />}

      <div className="appContent">
        <Layout showNavbar={showNavbar}>
          <Routes>
            <Route path="/" element={
              <Home
                welcomeDone={welcomeDone}
                isMobile={isMobile}
                output={output}
                setOutput={setOutput}
                directories={directories}
                setDirectories={setDirectories}
                contentRef={contentRef}
                openFile={openFile}
                currentDirectory={currentDirectory}
                setCurrentDirectory={setCurrentDirectory}
              />
            } />
            <Route path="/matrix-cam" element={<MatrixCam />} />
          </Routes>
        </Layout>
      </div>
    </Router>
  );
}

export default App;
