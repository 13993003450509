const openFileFromButton = (filePath, setOutput, contentRef, output) => {
  const baseStorageUrl = process.env.REACT_APP_FILE_STORAGE.replace(/\/+$/, '');
  const fileUrl = `${baseStorageUrl}/${filePath}`;

  console.log("Attempting to open file from button click:");
  console.log("File Path:", filePath);
  console.log("File URL:", fileUrl);

  const scrollToBottom = () => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  };

  if (filePath.toLowerCase().endsWith('.png') || filePath.toLowerCase().endsWith('.jpg') || filePath.toLowerCase().endsWith('.jpeg')) {
    const imageOutput = (
      <div key={output.length}>
        <span>{'> open '}{filePath}{'\n'}</span>
        <img src={fileUrl} alt={filePath} style={{ maxWidth: '100%', maxHeight: '500px', objectFit: 'contain' }} onLoad={scrollToBottom} onError={(e) => console.error('Error loading image:', e, fileUrl)} />
      </div>
    );
    setOutput(prevOutput => [...prevOutput, imageOutput]);
  } else if (filePath.toLowerCase().endsWith('.mp4') || filePath.toLowerCase().endsWith('.mov')) {
    const videoType = filePath.toLowerCase().endsWith('.mp4') ? 'video/mp4' : 'video/quicktime';
    const videoOutput = (
      <div key={output.length}>
        <span>{'> open '}{filePath}{'\n'}</span>
        <video
          controls
          style={{ maxWidth: '100%', maxHeight: '500px', objectFit: 'contain' }}
          onLoadedData={scrollToBottom}
          onError={(e) => console.error('Error loading video:', e, fileUrl)}
        >
          <source src={fileUrl} type={videoType} />
          Your browser does not support the video tag.
        </video>
      </div>
    );
    setOutput(prevOutput => [...prevOutput, videoOutput]);
  } else {
    fetch(fileUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('File not found');
        }
        return response.text();
      })
      .then(data => {
        // Function to convert URLs in text to clickable links
        const convertToLinks = (text) => {
          const urlRegex = /https?:\/\/[^\s]+/g;
          return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
        };

        // Convert text with URLs to HTML
        const htmlData = convertToLinks(data);

        const htmlOutput = (
          <div key={output.length}>
            <span>{'> open '}{filePath}{'\n'}</span>
            <div dangerouslySetInnerHTML={{ __html: htmlData }} />
          </div>
        );

        setOutput(prevOutput => [...prevOutput, htmlOutput]);
      })
      .catch(error => {
        console.error('Error fetching file:', error, fileUrl);
        setOutput(prevOutput => [...prevOutput, `> open ${filePath}`, 'Error: File not found']);
      });
  }
};

export default openFileFromButton;
