// WelcomeAnimation.js
import React, { useEffect } from 'react';
import styles from './WelcomeAnimation.module.css';

const WelcomeAnimation = ({ onDone }) => {
  useEffect(() => {
    // After a total duration, call onDone
    const doneTimer = setTimeout(() => {
      onDone();
    }, 2000); // Total time for the welcome animations

    return () => {
      clearTimeout(doneTimer);
    };
  }, [onDone]);

  return (
    <div className={styles.fullscreen}>
      <div className={styles.welcomeText}>Welcome</div>
    </div>
  );
};

export default WelcomeAnimation;
