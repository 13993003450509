import React, { useEffect, useRef } from 'react';
import styles from './MatrixRain.module.css';

const MatrixRain = ({ show, opacity }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'c') {
        window.location.href = 'https://www.matt-os.com'; // Navigate to matt-os.com
      }
    };

    if (show && window.location.pathname === '/matrix-cam') {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Function to update the canvas size and reinitialize the rain
      const initializeCanvas = () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const columns = Math.floor(canvas.width / 20);
        return Array.from({ length: columns }).map(() => 
          Math.random() * canvas.height
        );
      };

      let yPositions = initializeCanvas();

      const draw = () => {
        context.fillStyle = 'rgba(21, 22, 22, 0.05)';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = '#0F0';
        context.font = '15pt MatrixCode';

        yPositions.forEach((y, index) => {
          const text = String.fromCharCode(Math.random() * 128);
          const x = index * 20;
          context.fillText(text, x, y);

          if (y > 100 + Math.random() * 10000) {
            yPositions[index] = 0;
          } else {
            yPositions[index] = y + 20;
          }
        });
      };

      const intervalId = setInterval(draw, 50);

      // Event listener for window resize
      const onResize = () => {
        yPositions = initializeCanvas();
      };

      window.addEventListener('resize', onResize);
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        clearInterval(intervalId);
        window.removeEventListener('resize', onResize);
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [show]);

  return <canvas ref={canvasRef} style={{ opacity: opacity }} className={styles.fullscreenCanvas} />;
};

export default MatrixRain;
