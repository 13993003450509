import React, { useState } from 'react';
import styles from './DropdownMenu.module.css';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as DiscordIcon } from '../../../assets/icons/discord.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/youtube.svg';
import { ReactComponent as TwitchIcon } from '../../../assets/icons/twitch.svg';
import { ReactComponent as PatreonIcon } from '../../../assets/icons/patreon.svg';
import { ReactComponent as BuyMeACoffeeIcon } from '../../../assets/icons/buymeacoffee.svg';
import { ReactComponent as GithubIcon } from '../../../assets/icons/github.svg';
import { ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevron-left.svg';
import { ReactComponent as AmazonIcon } from '../../../assets/icons/amazon.svg'; // Import Amazon icon

// Social Widgets
import { YouTubeEmbed } from 'react-social-media-embed';
import { InstagramEmbed } from 'react-social-media-embed';
import { TwitterEmbed } from 'react-social-media-embed';

function DropdownMenu() {
  const [activeMenu, setActiveMenu] = useState('socials');
  const [menuHeight, setMenuHeight] = useState(null);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <a
        href="#"
        className={styles.menuItem}
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className={styles.iconButton}>{props.leftIcon}</span>
        {props.children}
        <span className={styles.iconRight}>{props.rightIcon}</span>
      </a>
    );
  }

  return (
    <div className={styles.dropdown} style={{ height: menuHeight }}>
      {/* Socials Main Menu */}
      <CSSTransition
        in={activeMenu === 'socials'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuPrimaryEnter,
          enterActive: styles.menuPrimaryEnterActive,
          exit: styles.menuPrimaryExit,
          exitActive: styles.menuPrimaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className="menu">
          {/* Youtube Menu Title */}
          <DropdownItem
            leftIcon={<YoutubeIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="youtube"
          >
            Youtube
          </DropdownItem>

          {/* Twitch Menu Title */}
          <DropdownItem
            leftIcon={<TwitchIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="twitch"
          >
            Twitch
          </DropdownItem>

          {/* Discord Menu Title */}
          <DropdownItem
            leftIcon={<DiscordIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="discord"
          >
            Discord
          </DropdownItem>

          {/* Github Menu Title */}
          <DropdownItem
            leftIcon={<GithubIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="github"
          >
            Github
          </DropdownItem>

          {/* Instagram Menu Title */}
          <DropdownItem
            leftIcon={<InstagramIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="instagram"
          >
            Instagram
          </DropdownItem>

          {/* Twitter Menu Title */}
          <DropdownItem
            leftIcon={<TwitterIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="twitter"
          >
            Twitter
          </DropdownItem>

          {/* Patreon Menu Title */}
          <DropdownItem
            leftIcon={<PatreonIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="patreon"
          >
            Patreon
          </DropdownItem>

          {/* Buy Me a Coffee Menu Title */}
          <DropdownItem
            leftIcon={<BuyMeACoffeeIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="buymeacoffee"
          >
            Buy Me a Coffee
          </DropdownItem>

          {/* Amazon Affiliates Menu Title */}
          <DropdownItem
            leftIcon={<AmazonIcon />}
            rightIcon={<ChevronRight />}
            goToMenu="amazon"
          >
            Amazon Affiliates
          </DropdownItem>
        </div>
      </CSSTransition>

      {/* Secondary Menus */}
      {/* Youtube Menu */}
      <CSSTransition
        in={activeMenu === 'youtube'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuSecondaryEnter,
          enterActive: styles.menuSecondaryEnterActive,
          exit: styles.menuSecondaryExit,
          exitActive: styles.menuSecondaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className={styles.menu}>
          <div className={styles.secondaryMenu}>
            <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
              Check out the latest video below...
            </DropdownItem>

            <div className={styles.widget}>
              <YouTubeEmbed
                url="https://www.youtube.com/watch?v=oRIMIscqbKY?sub_confirmation="
                width={325}
                height={220}
              />
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Twitch Menu */}
      <CSSTransition
        in={activeMenu === 'twitch'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuSecondaryEnter,
          enterActive: styles.menuSecondaryEnterActive,
          exit: styles.menuSecondaryExit,
          exitActive: styles.menuSecondaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className="menu">
          <div className={styles.secondaryMenu}>
            <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
              Watch me Live on Twitch...
            </DropdownItem>

            <div className={styles.widget}>
              <a
                href="https://www.twitch.tv/skintrichkid"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to Twitch
              </a>
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Discord Menu */}
      <CSSTransition
        in={activeMenu === 'discord'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuSecondaryEnter,
          enterActive: styles.menuSecondaryEnterActive,
          exit: styles.menuSecondaryExit,
          exitActive: styles.menuSecondaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className="menu">
          <div className={styles.secondaryMenu}>
            <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
              Join the discord...
            </DropdownItem>

            <div className={styles.widget} style={{ position: 'relative' }}>
              <a
                href="https://discord.gg/EmdrJz4MYj"
                target="_blank"
                rel="noopener noreferrer"
                style={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  width: '100%', 
                  height: '100%', 
                  zIndex: 10 
                }}
              >
                <span style={{ display: 'block', width: '100%', height: '100%' }}></span>
              </a>
              <iframe
                src="https://discord.com/widget?id=589931165775822849&theme=dark"
                width="100%"
                height="500"
                allowTransparency="true"
                frameBorder="0"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                title="Discord Widget"
              ></iframe>
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Github Menu */}
      <CSSTransition
        in={activeMenu === 'github'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuSecondaryEnter,
          enterActive: styles.menuSecondaryEnterActive,
          exit: styles.menuSecondaryExit,
          exitActive: styles.menuSecondaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className="menu">
          <div className={styles.secondaryMenu}>
            <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
              Check out my GitHub...
            </DropdownItem>

            <div className={styles.widget}>
              <a
                href="https://github.com/MattBanham"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to GitHub
              </a>
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Instagram Menu */}
      <CSSTransition
        in={activeMenu === 'instagram'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuSecondaryEnter,
          enterActive: styles.menuSecondaryEnterActive,
          exit: styles.menuSecondaryExit,
          exitActive: styles.menuSecondaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className="menu">
          <div className={styles.secondaryMenu}>
            <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
              Follow me on Instagram...
            </DropdownItem>

            <div className={styles.widget}>
              <InstagramEmbed
                url="https://www.instagram.com/skintrichkid/"
                width={328}
                height={467}
              />
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Twitter Menu */}
      <CSSTransition
        in={activeMenu === 'twitter'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuSecondaryEnter,
          enterActive: styles.menuSecondaryEnterActive,
          exit: styles.menuSecondaryExit,
          exitActive: styles.menuSecondaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className="menu">
          <div className={styles.secondaryMenu}>
            <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
              Follow me on Twitter...
            </DropdownItem>

            <div className={styles.widget}>
              <TwitterEmbed
                url="https://x.com/mabanham/status/1726660748624163312?s=20"
                width={325}
                height={228}
              />
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Patreon Menu */}
      <CSSTransition
        in={activeMenu === 'patreon'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuSecondaryEnter,
          enterActive: styles.menuSecondaryEnterActive,
          exit: styles.menuSecondaryExit,
          exitActive: styles.menuSecondaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className="menu">
          <div className={styles.secondaryMenu}>
            <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
              Support me on Patreon...
            </DropdownItem>

            <div className={styles.widget}>
              <a
                href="https://www.patreon.com/MattBanham"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to Patreon
              </a>
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Buy Me a Coffee Menu */}
      <CSSTransition
        in={activeMenu === 'buymeacoffee'}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: styles.menuSecondaryEnter,
          enterActive: styles.menuSecondaryEnterActive,
          exit: styles.menuSecondaryExit,
          exitActive: styles.menuSecondaryExitActive,
        }}
        onEnter={calcHeight}
      >
        <div className="menu">
          <div className={styles.secondaryMenu}>
            <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
              Support me by buying me a coffee...
            </DropdownItem>

            <div className={styles.widget}>
              <a
                href="https://www.buymeacoffee.com/mattbanham"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to Buy Me a Coffee
              </a>
            </div>
          </div>
        </div>
      </CSSTransition>

      {/* Amazon Affiliates Menu */}
        <CSSTransition
          in={activeMenu === 'amazon'}
          unmountOnExit
          timeout={500}
          classNames={{
            enter: styles.menuSecondaryEnter,
            enterActive: styles.menuSecondaryEnterActive,
            exit: styles.menuSecondaryExit,
            exitActive: styles.menuSecondaryExitActive,
          }}
          onEnter={calcHeight}
        >
          <div className="menu">
            <div className={styles.secondaryMenu}>
              <DropdownItem leftIcon={<ChevronLeft />} goToMenu="socials">
                Check out my Amazon Affiliate links...
              </DropdownItem>

              <div className={styles.widget}>
                <h3 className={styles.header}>Glados Project</h3>
                <ul className={styles.amazonList}>
                  <li><a href="https://amzn.to/45O6SqN">Paint Primer</a></li>
                  <li><a href="https://amzn.to/4bp73tO">Clear Gloss</a></li>
                  <li><a href="https://amzn.to/3L8RoUH">White Paint</a></li>
                  <li><a href="https://amzn.to/3zqp0e5">Black Paint</a></li>
                  <li><a href="https://amzn.to/3RSSY0O">Oily Steel Paint</a></li>
                  <li><a href="https://amzn.to/4eGWFQW">5v 30w Power supply (recommended)</a></li>
                  <li><a href="https://amzn.to/4crqbbR">12v 30w Power supply (alternative)</a></li>
                  <li><a href="https://amzn.to/3XKsfr2">3W RGB LED lights</a></li>
                  <li><a href="https://amzn.to/3WbiUYd">Quiet 40mm*20mm 5v fans</a></li>
                  <li><a href="https://amzn.to/3LcBita">DHT22 - Temp and Humidity sensor module</a></li>
                  <li><a href="https://amzn.to/4cJx9c0">Elegoo UNO Arduino starter kit</a></li>
                  <li><a href="https://amzn.to/4cqfEgZ">ESP32 + development board</a></li>
                  <li><a href="https://amzn.to/3xR5mre">Amber LED</a></li>
                  <li><a href="https://amzn.to/4btUGN8">Flashforge PLA 3d print filament - White</a></li>
                  <li><a href="https://amzn.to/4bvcoQp">Voxlab Aqulia 3D Printer</a></li>
                  <li><a href="https://amzn.to/3zwHUQH">Orange 22 gauge Wire</a></li>
                </ul>
                <h3 className={styles.header}>Other Stuff I Use Every Day</h3>
                <ul className={styles.amazonList}>
                  <li><a href="https://amzn.to/4cMfyQD">99.9% Isopropyl Alcohol (cleaning print bed for perfect adhesion every time)</a></li>
                  <li><a href="https://amzn.to/3L7Rzj8">Eryone Black PETG filament</a></li>
                  <li><a href="https://amzn.to/4coIiPG">Breadboard cable kit</a></li>
                  <li><a href="https://amzn.to/3VMJbdX">The Ultimate mutli-tool desk cleaner</a></li>
                  <li><a href="https://amzn.to/4bpFGQo">Draper Curved Secateurs</a></li>
                  <li><a href="https://amzn.to/4cuRrqa">Draper Straight Secateurs</a></li>
                  <li><a href="https://amzn.to/3LvsOOh">Overhead Desk Light</a></li>
                  <li><a href="https://amzn.to/3XMjcWr">Razer Basilisk V3</a></li>
                  <li><a href="https://amzn.to/45V55Aq">Magsafe Monitor Iphone Clip</a></li>
                  <li><a href="https://amzn.to/3VToWeq">3 in 1 charger (fixed/rigid, recommended for Desk)</a></li>
                  <li><a href="https://amzn.to/3VNP8qZ">3 in 1 charger (adjustable/foldable, recommended for bedside and/or travel)</a></li>
                  <li><a href="https://amzn.to/4eLiAq9">Streamdeck XL</a></li>
                  <li><a href="https://amzn.to/4bqRVMp">RTX 3060 12gb VRAM</a></li>
                  <li><a href="https://amzn.to/3LdGXiR">Speakers - Good Budget speakers</a></li>
                  <li><a href="https://amzn.to/4bwZgdF">Cable management Kit</a></li>
                  <li><a href="https://amzn.to/45VsuBx">Hyperx QuadCast S Microphone</a></li>
                  <li><a href="https://amzn.to/4ctrTcN">4K monitor 27"</a></li>
                  <li><a href="https://amzn.to/3zreO5l">LG UltraGear Curved Gaming Monitor 34"</a></li>
                  <li><a href="https://amzn.to/3VT0ES5">Monitor Arm</a></li>
                  <li><a href="https://shorturl.at/f1UVk">Breadboards - Busboard830s (non-affiliate link, everything on amazon sucks)</a></li>
                </ul>
              </div>
            </div>
          </div>
        </CSSTransition>
    </div>
  );
}

export default DropdownMenu;
