import React from 'react';
import styles from './DescriptionSection.module.css';

const DescriptionSection = () => {
  return (
    <div className={styles.descriptionSection}>
      <h1>Matt Banham</h1>
      <p>Full Stack Developer, Tech Enthusiast, Musician and Maker.</p>
      <p>I Like building things.</p>
    </div>
  );
};

export default DescriptionSection;