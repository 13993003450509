const changeDirectory = (input, currentDirectory, directories, setCurrentDirectory) => {
  const parts = input.trim().split(' ');  // Ensure any leading/trailing whitespace is removed and split by space
  let targetDir = parts.length > 1 ? parts.slice(1).join(' ') : '.'; // Join back parts to handle directories with spaces
  let message = `> ${input}`;
  console.log("Attempting to change directory from:", currentDirectory, "to", targetDir);

  // Convert directories keys to lowercase for case-insensitive comparison
  const lowercaseDirectories = Object.keys(directories).reduce((acc, key) => {
    acc[key.toLowerCase()] = key;
    return acc;
  }, {});

  // Handle home directory shortcut
  if (targetDir === '~') {
    targetDir = '/root';  // Assuming /root is the home directory
  }

  // Handle absolute paths
  if (targetDir.startsWith('/')) {
    targetDir = targetDir.replace(/\/+/g, '/');  // Normalize slashes
  } else {
    // Handle relative paths
    const segments = currentDirectory.split('/').concat(targetDir.split('/'));
    const normalizedSegments = [];
    
    segments.forEach(segment => {
      if (segment === '' || segment === '.') {
        // Ignore empty or current directory segments
        return;
      }
      if (segment === '..') {
        // Remove the last segment if navigating up
        if (normalizedSegments.length > 0) {
          normalizedSegments.pop();
        }
      } else {
        normalizedSegments.push(segment);
      }
    });

    targetDir = '/' + normalizedSegments.join('/');  // Normalize path
  }

  const targetDirLowercase = targetDir.toLowerCase();

  // Ensure path is within the root directory
  if (targetDirLowercase.startsWith('/root') || targetDirLowercase === '/root') {
    if (lowercaseDirectories[targetDirLowercase]) {
      setCurrentDirectory(lowercaseDirectories[targetDirLowercase]);
      message += `\n> Moved to ${lowercaseDirectories[targetDirLowercase]}`;
    } else {
      message += '\n> Directory not found';
    }
  } else {
    message += '\n> Directory not found';
  }

  return message;
};

export default changeDirectory;
