import React from 'react';
import styles from './Navbar.module.css';  // Import the CSS module

function Navbar(props) {
  return(
    <nav className={styles.navbar}>
      <h1 className={styles.logo}>Matt-OS</h1>  {/* Apply styles.logo */}
      <ul className={styles.navbarNav}>{props.children}</ul>  {/* Apply styles.navbarNav */}
    </nav>
  )
}

export default Navbar;
