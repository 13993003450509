// src/components/home/Home.js

import React from 'react';
import AboutMe from './AboutMe';
import DescriptionSection from './DescriptionSection';
import MatrixRain from './MatrixRain';
import LeftOverlay from './sliding-overlay/LeftOverlay';
import RightOverlay from './sliding-overlay/RightOverlay';
import LatestUpdates from './LatestUpdates';
import MainContent from './MainContent';
import ProjectCarousel from './ProjectCarousel';
import openFileFromButton from './MainContent/commands/openFileFromButton';

const Home = ({
  welcomeDone,
  isMobile,
  output,
  setOutput,
  directories,
  setDirectories,
  contentRef,
  openFile,
  currentDirectory,
  setCurrentDirectory,
}) => {
  return (
    <>
      {!isMobile && <LeftOverlay show={!welcomeDone} />}
      {!isMobile && (
        <LatestUpdates
          openFileFromButton={(filePath) => openFileFromButton(filePath, setOutput, contentRef, output)}
        />
      )}
      <MainContent
        output={output}
        setOutput={setOutput}
        directories={directories}
        setDirectories={setDirectories}
        contentRef={contentRef}
        openFile={openFile}
        currentDirectory={currentDirectory}
        setCurrentDirectory={setCurrentDirectory}
      />
      {!isMobile && (
        <div className="aboutMeAndOverlay">
          <div className="container">
            <AboutMe show={!welcomeDone} />
            <DescriptionSection />
            <div className="fadeAnimation"></div>
            <ProjectCarousel show={!welcomeDone} />
          </div>
          <RightOverlay show={!welcomeDone} />
        </div>
      )}
    </>
  );
};

export default Home;
