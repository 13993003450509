const handleRunCommand = (command, setOutput) => {
    const normalizedCommand = command.trim().toLowerCase();
    if (normalizedCommand === 'run matrixcam' || normalizedCommand === 'run matrix-cam.exe') {
      setOutput(prevOutput => [...prevOutput, `> ${command}`, 'Running matrix-cam...']);
      window.location.href = 'https://www.matt-os.com/matrix-cam';
    } else {
      setOutput(prevOutput => [...prevOutput, `> ${command}`, 'Error: Command not recognized']);
    }
  };
  
  export default handleRunCommand;
  