import React from 'react';

const ListDirectoryContents = ({ currentDirectory, directories }) => {
    console.log("Current Directory:", currentDirectory);
    console.log("Directories Object:", directories);

    // Get directories and files in the current directory
    const subdirectories = Object.keys(directories).filter(dir => dir.startsWith(currentDirectory) && dir !== currentDirectory);
    const files = directories[currentDirectory] || [];

    console.log("Subdirectories:", subdirectories);
    console.log("Files in current directory:", files);

    if (subdirectories.length === 0 && files.length === 0) {
        return (
            <div>
                <span style={{ color: 'grey' }}>{'> '}</span>
                <span>No files or directories found in this directory.</span>
            </div>
        );
    }

    return (
        <div>
            {subdirectories.map((dir, index) => (
                <div key={index}>
                    <span style={{ color: 'grey' }}>{'> '}</span>
                    <span style={{ color: '#4974a5' }}>
                        {dir.substring(currentDirectory.length + 1)}/
                    </span>
                </div>
            ))}
            {files.map((file, index) => (
                <div key={index}>
                    <span style={{ color: 'grey' }}>{'> '}</span>
                    <span style={{ color: '#f93816' }}>
                        {file}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default ListDirectoryContents;
