import React from 'react';
import styles from './AboutMe.module.css';

const AboutMe = ({ show }) => {

  return (
    <div className={`${styles.aboutMeContainer} `}>
      <div className={`${styles.aboutMeFade} `}>
        <img 
            src={`${process.env.REACT_APP_FILE_STORAGE}images/profile-alt.png`}
            alt="Profile Img" 
            className={styles.profilePhoto} 
        />
      </div> 
    </div>
  );
};

export default AboutMe;
