import React, { useState } from 'react';
import styles from './ProjectCarousel.module.css';

const ProjectCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

const projects = [
  { title: 'LED Matrix Cube', description: '3*(64*64) LED Matrices make up a cyberpunk style cube', imageUrl: `${process.env.REACT_APP_FILE_STORAGE}images/cube.png` },
  { title: 'GLaDOS', description: '3D printed and hand painted animatronic model of GLaDOS from Portal', imageUrl: `${process.env.REACT_APP_FILE_STORAGE}images/glados2.png` },
  { title: 'Matrix Webcam', description: 'A customizable Ascii / Matrix style webcam', imageUrl: `${process.env.REACT_APP_FILE_STORAGE}images/matrixcam.jpg` },
  { title: 'LUNA AI Personal Assistant', description: 'A ChatGPT agent utilizing langchain to add a bunch of additional features', imageUrl: `${process.env.REACT_APP_FILE_STORAGE}images/luna2.png` },
  { title: 'Matt-OS', description: 'Matrix style website with a terminal control logic for project documentation and blogging', imageUrl: `${process.env.REACT_APP_FILE_STORAGE}images/matt-os-terminal.png` },
  { title: 'Binance Trading Dashboard', description: 'Binance data scraper and dashboard utilizing Binance APIs', imageUrl: `${process.env.REACT_APP_FILE_STORAGE}images/binance-dashboard.png` }
  // Other projects....

];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };
  
  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  return (
    <div className={styles.carouselContainer}>
      {projects.map((project, index) => {
        let className = styles.projectSlide;
        let imageClassName = styles.projectImage; // Default image class

        // Add conditional class for GLaDOS image
        if (project.title === 'GLaDOS') {
          imageClassName += ` ${styles.gladosImage}`;
        }

        if (index === activeIndex) {
          className += ` ${styles.activeSlide}`;
        } else if (index === (activeIndex - 1 + projects.length) % projects.length) {
          className += ` ${styles.sideSlide} ${styles.leftSlide}`;
        } else if (index === (activeIndex + 1) % projects.length) {
          className += ` ${styles.sideSlide} ${styles.rightSlide}`;
        }

        return (
          <div key={index} className={className}>
            <img src={project.imageUrl} alt={project.title} className={imageClassName} />
          </div>
        );
      })}
      <div className={styles.carouselInfo}>
        <h3>{projects[activeIndex].title}</h3>
        <p>{projects[activeIndex].description}</p>
      </div>
      <button className={styles.prevButton} onClick={prevSlide}>&lt;</button>
      <button className={styles.nextButton} onClick={nextSlide}>&gt;</button>
    </div>
  );
};

export default ProjectCarousel;