import React, { useState } from 'react';
import styles from './NavItem.module.css';

function NavItem(props) {
  const [open, setOpen] = useState(false);

  return (
    <li className={styles.navItem}> {/* Apply styles.navItem */}
      {props.href ? (
        <a href={props.href} className={styles.iconButton} target="_blank" rel="noopener noreferrer"> {/* Apply styles.iconButton */}
          {props.icon}
        </a>
      ) : (
        <a href="#" className={styles.iconButton} onClick={() => setOpen(!open)}> {/* Apply styles.iconButton */}
          {props.icon}
        </a>
      )}

      {open && props.children}
    </li>
  );
}

export default NavItem;
