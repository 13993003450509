import React, { useState, useEffect } from 'react';

const WebcamSelector = ({ selectedDevice, setSelectedDevice }) => {
    const [devices, setDevices] = useState([]);

    // Fetch video devices
    useEffect(() => {
        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                setDevices(videoDevices);

                if (videoDevices.length > 0 && !selectedDevice) {
                    setSelectedDevice(videoDevices[0].deviceId); // Initialize with the first camera
                }
            })
            .catch((error) => {
                console.error('Error accessing media devices:', error);
            });
    }, [selectedDevice]);

    const handleDeviceChange = (event) => {
        setSelectedDevice(event.target.value); // Update the selected device
    };

    return (
        <div>
            <label htmlFor="webcam-select">Choose a Camera:</label>
            <select id="webcam-select" value={selectedDevice} onChange={handleDeviceChange}>
                {devices.map((device, index) => (
                    <option key={device.deviceId} value={device.deviceId}>
                        {device.label || `Camera ${index + 1}`}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default WebcamSelector;
